
export default {
  name: "GeneratorForm",
  data() {
    return {
      topic: "",
      temperature: "70",
      max_tokens: "256",
      modalVisible: false,
      result: "",
    };
  },
  methods: {
    async onSubmit() {
      const res = await fetch("http://localhost:5099/api/generate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          topic: this.topic,
          temperature: Number(this.temperature) / 100,
          max_tokens: Number(this.max_tokens),
        }),
      });

      const { text } = await res.json();
      this.result = text;
      this.modalVisible = true;
    },
  },
};
