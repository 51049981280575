
import { Component, Vue } from "vue-property-decorator";
import GeneratorForm from "./components/GeneratorForm.vue";

@Component({
  components: {
    GeneratorForm,
  },
  data() {
    return {
      areLeftPanels: false,
      areRightPanels: false,
    };
  },
})
export default class App extends Vue {}
