// import { CarbonIconsVue, Login20, Notification20 } from "@carbon/icons-vue";
import Vue from "vue";
// import "carbon-components/scss/globals/scss/styles.scss";
import "@/scss/main.scss";
import CarbonComponentsVue from "@carbon/vue";

Vue.use(CarbonComponentsVue);

import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";

Vue.config.productionTip = false;
// Vue.use(CarbonIconsVue, {
//   components: {
//     Login20,
//     Notification20
//   },
// });

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
